<template>
  <div class="bg-grey">
    <!-- Navbar :: start -->
    <b-row v-if="!this.$cookies.get('company_id')">
      <b-col col md="12">
        <NavbarCompNew />
      </b-col>
    </b-row>
    <MainHeader v-if="this.$cookies.get('company_id')" />
    <!-- Navbar :: end -->
    <b-container fluid>
      <b-row class="pt-6 mt-5 px-md-3">
        <b-col class="col-md-12 bg-white rounded-lg py-3">
          <h1 class="border-bottom">{{ $gettext("Employers") }}</h1>

          <div>
            <!-- <p>
              Aipplicant is an AI based platform designed to create a seamless process from screening to shortlist to
              selection. We aim to overcoming the challenges created by paper-based CVs that do not reflect the true
              personality, passion, and cultural fit of the applicant. We have set the stage where applicants can take
              advantage of the video format to create a compelling pitch to you . In spite the use of technology, the
              process from screening to shortlist to selection continues to be a
              complex challenge faced by recruiters and employers. We aim to minimize the burden of screening large volume
              of applications using artificial intelligence. From small startups to large enterprises, every company will
              gain benefit from this portal. Our aim is to make your talent acquisition process faster , more accurate and
              affordable .
            </p>
            <p>
              <b>How do we make a difference?</b>
            </p>
            <p><b> Fast Track screening:</b> Ability to fast track the screening process by asking 3-10 screening
              questions.</p>
            <p><b> AI Scoring Engine: </b>Plays the role of ranking applicants on not just their answers, but also
              considers the skills described by the applicant and matches it against skills they have listed in their
              profile and forms a consolidated score and sees a list of all applicants sorted by top score</p>
            <p><b>AI Applicant suggestion:</b> When a new job is posted by the employer, it checks prior applicant history
              and suggests applicants that are relevant to the new job being posted, saving time and effort</p>
            <p><b> Cultural fitment :</b> Besides evaluating technical skills , its as important for employers to judge
              culturally fitment. You can engage an applicant with an EQ and CQ test to learn about the candidate’s
              personality.</p>
            <p><b>Dashboard:</b> The applicant has the ability to filter and view profiles along with submitted videos or
              written profiles and share with other stakeholders involved in the selection process</p>
            <p><b>Face 2 Face:</b> Employers will be able to contact applicants directly for the next round of a video /
              telephonic interview</p>
            <p><b>LIVE Status:</b> At every stage the applicant is kept informed of their progress, viewed, rejected,
              shortlisted, 2nd round interviews etc. All the way to closure</p>
            <p><b>AI Suggestions:</b> On the basis of the job title and description, an AI engine will suggest relevant
              questions to the employer that they can use for the role</p>
            <p><b>Virtual video interview:</b> Employers can position a video question that applicants can submit through
              a 30 second video clip</p>
            <p>The above aims to close the huge problem that employers face in selecting the best possible applicant. It
              also aims to help the applicant and empowers them to make a difference with a 60 second elevator pitch
              expressing their personality.</p> -->
            <p>
              Zaajira is an AI based platform designed to create a seamless process from screening to shortlist to
              selection. We aim to overcoming the challenges created by paper-based CVs that do not reflect the true
              personality, passion, and cultural fit of the candidate.<br />

              In spite the use of technology, the process from screening to shortlist to selection continues to be a
              complex challenge faced by recruiters and employers. We aim to minimize the burden of screening large volume
              of applications using artificial intelligence. From small startups to large enterprises, every company will
              gain benefit from this portal. Our aim is to make your talent acquisition process faster , more accurate and
              affordable .
            </p>
            <p>
              <b>How do we make a difference? </b>
            </p>
            <p><b> Fast Track screening:</b> Ability to fast track the screening process by asking 3-10 screening
              questions.</p>
            <p><b> AI Scoring Engine: </b>Plays the role of ranking applicants on not just their answers, but also
              considers the skills described by the applicant and matches it against skills they have listed in their
              profile and forms a consolidated score and sees a list of all applicants sorted by top score</p>
            <p><b>AI Applicant suggestion:</b> Plays the role of ranking candidates on not just their answers, but also
              considers the skills described by the candidate and matches it against skills they have listed in their
              profile and forms a consolidated score and sees a list of all candidates sorted by top score </p>
            <p><b>AI Candidate suggestion:</b> When a new job is posted by the employer, it checks prior candidate history
              and suggests candidates that are relevant to the new job being posted, saving time and effort </p>
            <p><b>Dashboard:</b> The candidate has the ability to filter and view profiles along with submitted videos or
              written profiles and share with other stakeholders involved in the selection process </p>
            <p><b>Face 2 Face:</b> Employers will be able to contact candidates directly for the next round of a video /
              telephonic interview </p>
            <p><b>LIVE Status:</b> At every stage the candidate is kept informed of their progress, viewed, rejected,
              shortlisted, 2nd round interviews etc. All the way to closure </p>
            <p><b>AI Suggestions:</b> On the basis of the job title and description, an AI engine will suggest relevant
              questions to the employer that they can use for the role</p>
            <!-- <p><b>Virtual video interview:</b> Employers can position a video question that applicants can submit through
              a 30 second video clip</p> -->
            <p>The above aims to close the huge problem that employers face in selecting the best possible candidate. It
              also aims to help the candidate and empowers them to make a difference with a 60 second elevator pitch
              expressing their personality.</p>


            <div class="text-center">
              <button v-if="!$store.getters.getLoggedUser.userToken" @click="() => $router.push({ path: '/register' })"
                class="btn btn-primary">Register now, for FREE</button>

              <button v-if="$store.getters.getLoggedUser.userToken && $store.getters.getLoggedUser.userRole == 2"
                @click="() => $router.push({ name: 'Employer-Profile' })" class="btn btn-primary">Get Started</button>
            </div>
            <div id="pricing">
              <Pricing />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Pricing from './Pricing.vue';
import NavbarCompNew from "../components/shared/NavbarCompNew.vue";
import MainHeader from "@/views/partials/MainHeader.vue";
export default {
  components: { Pricing, NavbarCompNew, MainHeader },
  mounted() {
    // Check if the hash exists in the URL and scroll to the section
    if (window.location.hash === '#pricing') {
      this.scrollToSection();
    }
  },
  methods: {
    scrollToSection() {
      const sectionElement = document.getElementById('pricing');
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
}
</script>

<style scoped></style>
